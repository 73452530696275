/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import "../services-components/service-sub-components/service-js.js";
import "../../assets/js/textTranform.js";
import HeaderBanner from "../common/HeaderBanner.js";
import ApplicationServiceComponent from "../services-components/service-sub-components/ApplicationServiceComponent.js";
import CloudServiceComponent from "../services-components/service-sub-components/CloudServiceComponent.js";
import { useLanguage } from "../../components/LanguageContext";

const ServicePage = () => {
  const { toggleLanguage, translate } = useLanguage();
  return (
    <main id="main">
      <HeaderBanner bannerName={translate("nav-services")} />
      <section id="company" style={{ backgroundColor: "white" }}>
        <div className="container mt-5">
          <div className="aos-init aos-animate">
            <article className="serviceEntry">
              <ApplicationServiceComponent translate={translate} />
              <CloudServiceComponent translate={translate} />
            </article>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ServicePage;
